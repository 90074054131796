import styled from 'styled-components'

export default styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .dropdown-label {
    margin-bottom: 16px;
  }
  .dropdown-wrapper {
    padding: 0 6px;
    border: 1px solid #981214;
    width: 189px;
    min-height: 24px;
    display: flex;
    align-items: center;
    & + .option-container {
      width: auto;
    }
    &:hover {
      cursor: pointer;
    }
    .values-wrapper {
      max-width: 180px;
      display: flex;
      flex-wrap: wrap;
      .input-dropdown {
        width: 80px;
        border: none;
        outline: none;
        background: inherit;
        color: white;
        ::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          font-family: 'Prompt', sans-serif;
          font-size: 12px;
          color: #fff;
          opacity: 1; /* Firefox */
        }
        ::-ms-input-placeholder {
          /* Microsoft Edge */
          font-family: 'Prompt', sans-serif;
          font-size: 12px;
          color: #fff;
        }
        &.only-search {
          width: 110px;
          height: 22px;
          line-height: 1;
        }
      }
      &.select-all {
        width: 140px;
        .value-tag {
          width: 100px;
          .tag-text {
            width: 80px;
          }
        }
      }
    }
  }
  .value-tag {
    width: 73px;
    margin-right: 2px;
    margin-top: 3px;
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    height: 18px;
    background-color: #660c0d;
    font-size: 12px;
    padding: 3px;
    .tag-text {
      width: 57px;
    }
    .icon-wrapper {
      margin-left: 8px;
      width: 16px;
      height: 16px;
      &:hover {
        cursor: pointer;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .dropdown-text {
    font-size: 12px;
  }
  .icon-wrapper {
    width: 16px;
    height: 16px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .option-container {
    visibility: hidden;
    position: absolute;
    background-color: #1e1e1e;
    z-index: 10;
    top: 100%;
    min-width: 189px;
    max-height: 120px;
    overflow-y: auto;
    &.open {
      visibility: visible;
    }
    .option-wrapper {
      height: 24px;
      border: 1px solid #981214;
      width: 100%;
      padding: 8px 9px;
      .checkbox-dropdown {
        .check-box {
          width: 8px;
          height: 8px;
        }
      }
    }
  }
`
