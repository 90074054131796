import React from 'react'
import PropTypes from 'prop-types'
import MultiSelectStyled from './styled'
import CheckBox from '../CheckBox/dynamic'
import Label from '../Label/dynamic'

import DownIconSrc from '../../assets/images/collapse/dropdown-down.svg'
import UpIconSrc from '../../assets/images/collapse/dropdown-up.svg'
import xIconSrc from '../../assets/images/visitor/unselect.svg'

class MultiSelectDropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownOpen: false,
      dropdownOptions: [],
      isSelectAll: false,
      isFocus: false,
      searchValue: '',
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    document.addEventListener('touchend', this.handleClickOutside)
    this.handleOptions()
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
    document.removeEventListener('touchend', this.handleClickOutside)
  }

  componentDidUpdate(prevProps) {
    const isValueChanged = prevProps.value !== this.props.value
    const isOptionsChanged = prevProps.options !== this.props.options
    if (isValueChanged || isOptionsChanged) {
      this.handleOptions()
    }
  }

  handleOptions() {
    const { options, value } = this.props
    const tempOptions = [...options]
    const output = tempOptions.reduce((prev, current) => {
      prev.push({ ...current, checked: value.includes(current.value) })
      return prev
    }, [])
    this.setState({
      dropdownOptions: output,
      isSelectAll: output.filter(option => option.checked).length === output.length,
    })
  }

  handleClickOutside = event => {
    if (this.input) {
      this.input.blur()
    }
    if (this.options && !this.options.contains(event.target) && !this.dropdownBox.contains(event.target)) {
      this.closeDropdown()
    }
  }

  handleClickOutsideHideDropdownMenu = e => {
    if (this.options && !this.options.contains(e.target)) {
      this.closeDropdown()
    }
  }

  toggleDropdown = () => {
    this.setState({
      isFocus: true,
      dropdownOpen: !this.state.dropdownOpen,
    })
  }

  closeDropdown = () => {
    this.setState({
      dropdownOpen: false,
    })
  }

  getLabel() {
    let output = null
    if (this.props.label) {
      output = <Label id={`${this.props.id}-label`} className="dropdown-label" text={this.props.label} disabled={this.props.disabled} />
    }
    return output
  }

  handleSearchInputChanged = e => {
    this.setState({
      searchValue: e.target.value,
    })
  }

  handleSearchInputBlur = () => {
    this.setState({
      isFocus: false,
    })
  }

  renderTagValues() {
    const { value } = this.props
    const { isSelectAll, dropdownOptions } = this.state
    const noneIsSelect = value.length === 0 || dropdownOptions.filter(option => option.checked).length === 0

    let output = null
    if (isSelectAll) {
      output = (
        <div className="value-tag">
          <div className="tag-text prevent-text-overflow">{this.props.allTagText}</div>
          <div className="icon-wrapper" onClick={() => this.onSelectAll(false)}>
            <img src={xIconSrc} />
          </div>
        </div>
      )
    } else if (noneIsSelect) {
      output = <div className="dropdown-text">{this.props.placeholder}</div>
      if (this.props.searchable) {
        output = (
          <input
            id={`${this.props.id}-search-input`}
            data-test={`${this.props.id}-search-input`}
            ref={ref => {
              this.input = ref
            }}
            className="input-dropdown only-search"
            type="text"
            placeholder={this.props.placeholder}
            value={this.state.searchValue}
            onChange={this.handleSearchInputChanged}
            onBlur={this.handleSearchInputBlur}
            disabled={this.props.disabled}
          />
        )
      }
    } else {
      const selectedOptions = dropdownOptions.filter(option => option.checked)
      const tempTags = selectedOptions.map(option => {
        return (
          <div key={option.value} className="value-tag">
            <div className="tag-text prevent-text-overflow">{option.text}</div>
            <div className="icon-wrapper" onClick={() => this.onEachCheckBoxChange(false, option.value)}>
              <img src={xIconSrc} />
            </div>
          </div>
        )
      })
      let searchInput = null
      if (this.props.searchable) {
        searchInput = (
          <input
            id={`${this.props.id}-search-input`}
            data-test={`${this.props.id}-search-input`}
            ref={ref => {
              this.input = ref
            }}
            className="input-dropdown"
            type="text"
            placeholder={''}
            value={this.state.searchValue}
            onChange={this.handleSearchInputChanged}
            onBlur={this.handleSearchInputBlur}
            disabled={this.props.disabled}
          />
        )
      }
      output = (
        <>
          {tempTags}
          {searchInput}
        </>
      )
    }
    let className = 'values-wrapper'
    className += isSelectAll ? ' select-all' : ''
    return <div className={className}>{output}</div>
  }

  renderDropdown() {
    const dropdownIcon = this.state.dropdownOpen ? UpIconSrc : DownIconSrc
    return (
      <div
        className="dropdown-wrapper flex space-between align-items-center"
        ref={ref => {
          this.dropdownBox = ref
        }}
        onClick={this.toggleDropdown}
      >
        {this.renderTagValues()}
        <div className="icon-wrapper flex justify-center align-items-center">
          <img src={dropdownIcon} />
        </div>
      </div>
    )
  }

  onSelectAll(checked) {
    const tempOptions = [...this.state.dropdownOptions]
    const tempOutput = tempOptions.map(each => {
      return { ...each, checked }
    })
    this.setState({
      dropdownOptions: tempOutput,
      isSelectAll: checked,
    })
    const newValue = tempOutput.filter(option => option.checked).map(option => option.value)
    this.props.onChange(newValue)
  }

  onEachCheckBoxChange(checked, value) {
    const tempOptions = [...this.state.dropdownOptions]
    const index = tempOptions.findIndex(option => option.value === value)
    const selectedCheckbox = tempOptions[index]
    const otherOptions = tempOptions.filter(option => option.value !== value)
    selectedCheckbox.checked = checked
    otherOptions.splice(index, 0, selectedCheckbox)
    this.setState({
      dropdownOptions: otherOptions,
      isSelectAll: otherOptions.filter(option => option.checked).length === otherOptions.length,
      searchValue: '',
    })
    const newValue = otherOptions.filter(option => option.checked).map(option => option.value)
    this.props.onChange(newValue)
  }

  renderDropdownOptions() {
    const isClient = typeof window !== 'undefined'
    const windowHeight = isClient ? window.innerHeight : 0
    const optionsBounding = this.options?.getBoundingClientRect()
    const dropdownBounding = this.dropdown?.getBoundingClientRect()
    const isOptionOverWindow = optionsBounding?.bottom > windowHeight
    const style = {
      transform: isOptionOverWindow ? `translate(0px, -${optionsBounding.height + dropdownBounding.height}px)` : 'initial',
      width: this.dropdownBox?.offsetWidth,
    }
    let availableOptions = this.state.dropdownOptions
    if (this.props.searchable) {
      availableOptions = this.state.dropdownOptions.filter(optionData => optionData.text.toLowerCase().includes(this.state.searchValue.toLowerCase()))
    }
    const output = availableOptions.map(option => {
      return (
        <div className="option-wrapper flex align-items-center" key={option.value}>
          <CheckBox
            className="checkbox-dropdown"
            checked={option.checked}
            label={option.text}
            value={option.value}
            onCheck={(checked, value) => this.onEachCheckBoxChange(checked, value)}
          />
        </div>
      )
    })
    let className = 'option-container'
    className += this.state.dropdownOpen ? ' open' : ''
    const numOfOptions = this.state.dropdownOptions.length
    const allChecked = this.state.dropdownOptions.filter(option => option.checked).length === numOfOptions
    return (
      <div
        id={`${this.props.id}-menu-box`}
        data-test={`${this.props.id}-menu-box`}
        ref={ref => {
          this.options = ref
        }}
        className={className}
        style={style}
        onMouseLeave={this.handleClickOutsideHideDropdownMenu}
      >
        <div className="option-wrapper flex align-items-center">
          <CheckBox
            className="checkbox-dropdown select-all-checkbox"
            checked={allChecked}
            label={this.props.allTagText}
            value="all"
            onCheck={checked => this.onSelectAll(checked)}
          />
        </div>
        {output}
      </div>
    )
  }

  render() {
    return (
      <MultiSelectStyled
        className={this.props.className}
        id={this.props.id}
        data-test={this.props.id}
        ref={ref => {
          this.dropdown = ref
        }}
      >
        {this.getLabel()}
        {this.renderDropdown()}
        {this.renderDropdownOptions()}
      </MultiSelectStyled>
    )
  }
}

MultiSelectDropdown.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  allTagText: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    }),
  ).isRequired,
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])).isRequired,
  onChange: PropTypes.func.isRequired,
  searchable: PropTypes.bool,
}

export default MultiSelectDropdown
